import React from 'react';
import {useOutlet, getOutlet} from 'reconnect.js';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import OrderDetail from 'rev.sdk.js/Templates/OrderPage/OrderDetail';
import styled from 'styled-components';
import * as CustomCheckout from '../../Utils/CustomCheckout';
import qs from 'query-string';
import * as AppActions from '../../AppActions';
import config from '../../../data.json';
import {DoNotDisturb} from '@styled-icons/material/DoNotDisturb';
import {Downloading} from '@styled-icons/material/Downloading';
import Panel from 'rev.sdk.js/Templates/OrderPage/SectionPanel';
import Field from 'rev.sdk.js/Components/OrderField';
import constants from '../../constants';

export default function BookingOrderDetailPage(props) {
  const [user] = useOutlet('user');
  const params = qs.parse(props.location?.search);
  const [order, setOrder] = React.useState(null);
  const [initial, setInitial] = React.useState(false);
  useOutlet('locale');

  const {from_date, to_date} = React.useMemo(() => {
    let from_date = '',
      to_date = '';
    if (order) {
      from_date = order.items[0].config.from_date;
      to_date = order.items[0].config.to_date;
    }
    return {from_date, to_date};
  }, [order]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const token = params.tmp_token || user.token;
        let _order = null;

        if (params.order_id) {
          _order = await ApiUtil.req(
            `${config.apiHost}/room-payment/order?order_id=${params.order_id}&token=${token}`,
          );
        } else {
          _order = await ApiUtil.req(
            `${config.apiHost}/room-payment/order?order_number=${params.order_number}&token=${token}`,
          );
        }

        _order.id = _order._id.$oid;
        setOrder(_order);
      } catch (ex) {}
      AppActions.setLoading(false);
      setInitial(true);
    }

    if (user && (params.order_id || params.order_number)) {
      fetchData();
    }
  }, [params.order_id, params.order_number, params.tmp_token, user]);
  if (!initial) {
    return (
      <Wrapper>
        <div className="content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Downloading size={28} style={{marginRight: 10}} />
            <div style={{fontSize: 24}}>{AppActions.L('loading')}...</div>
          </div>
        </div>
      </Wrapper>
    );
  } else if (initial && !order) {
    return (
      <Wrapper>
        <div className="content">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <DoNotDisturb size={28} style={{marginRight: 10}} />
            <div style={{fontSize: 24}}>{AppActions.L('order_not_found')}</div>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      subtotalLabel={AppActions.L('total')}
      totalLabel={AppActions.L('deposit')}>
      <div className="content">
        <OrderDetail
          order={order}
          supportReturn={false}
          enableInvoiceFeature={false}
          renderCustomComponent={CustomCheckout.renderCustomComponent}
          renderExtraPaymentInfo={(order) => (
            <React.Fragment>
              <Field
                name={AppActions.L('deposit')}
                value={'NT$ ' + order.total}
              />
              <Field
                name={AppActions.L('total')}
                value={'NT$ ' + order.subtotal}
              />
            </React.Fragment>
          )}
          renderExtraLogisticsInfo={false}
        />
        <Panel header={AppActions.L('booking_info')}>
          <Field name={AppActions.L('adults')} value={order.adults} />
          <Field name={AppActions.L('kids')} value={order.kids} />
          <Field name={AppActions.L('checkin_date')} value={from_date} />
          <Field name={AppActions.L('checkout_date')} value={to_date} />
          <Field
            name={AppActions.L('expect_arrival_time')}
            value={order.arrival_time}
          />
        </Panel>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .content {
    max-width: 720px;
    min-height: var(--contentMinHeight);
    margin: 0 auto;
    padding: var(--basePadding);
  }

  & .logistic-panel,
  .receiver-panel {
    display: none;
  }

  & .order-info-panel > .content > :nth-last-child(2) {
    display: none; /* hide order total field */
  }

  & .first-panel .price-summary .total {
    & > label {
      display: none;
    }
    &::before {
      content: '${(props) => props.totalLabel}';
      flex: 1;
    }
  }
  & .first-panel .price-summary .subtotal {
    & > label {
      display: none;
    }
    &::before {
      content: '${(props) => props.subtotalLabel}';
      flex: 1;
    }
  }

  & .cart-header > h2 {
    visibility: hidden;
  }

  & .price-summary {
    & .fee,
    .discount {
      display: none;
    }
  }
`;
